.admin-panel {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #000000;
  border-radius: 8px;
  color: white;
}

.admin-panel h1 {
  text-align: center;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

th, td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #444;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

th {
  background-color: #333;
}

/* Set specific widths for each column */
th:nth-child(1), td:nth-child(1) { width: 20%; }
th:nth-child(2), td:nth-child(2) { width: 25%; }
th:nth-child(3), td:nth-child(3) { width: 20%; }
th:nth-child(4), td:nth-child(4) { width: 15%; }
th:nth-child(5), td:nth-child(5) { width: 20%; }

.action-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.review-button {
  background-color: #4CAF50;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 10px;
  margin: 10px;
  white-space: nowrap;
}

.review-button:hover {
  background-color: #45a049;
}

.delete-button {
  background: none;
  border: none;
  color: #f44336;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  margin: 10px;
  transition: color 0.3s ease;
}

.delete-button:hover {
  color: #d32f2f;
}

.delete-button i {
  font-size: 16px;
}

/* Remove any conflicting styles */
.delete-button {
  background-color: transparent !important;
}

.delete-button:hover {
  background-color: transparent !important;
}