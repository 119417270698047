.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000000;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.google-sign-in {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0px;
  width: 280px; /* Slightly smaller than the logo */
  border-radius: 10px;
  height: auto;
}

.login-logo {
  width: 100%; /* Will fill the button width */
  height: auto;
  /* border: 4px solid white;
  border-radius: 10px; */
  background-color: black;
  display: block;
}

.error-message {
  color: #f44336;
  margin-top: 20px;
  text-align: center;
}