.review-application {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #000000;
  border-radius: 8px;
  color: white;
  width: 100%;
  box-sizing: border-box;
}

.review-application h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
}

.review-application h1 span {
  font-weight: bold;
}

.review-application .form-group {
  margin-bottom: 15px;
}

.review-application .form-group label {
  display: block;
  margin-bottom: 5px;
  color: #ddd;
}

.review-application .form-group input,
.review-application .form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #444;
  border-radius: 4px;
  background-color: #333;
  color: white;
}

.review-application .form-group textarea {
  height: 100px;
}

.review-application .form-group a {
  color: #4CAF50;
  text-decoration: none;
}

.review-application .form-group a:hover {
  text-decoration: underline;
}

.review-application .button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
  width: 100%; /* Ensure full width */
}

.review-application .action-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  color: white;
  width: 120px; /* Set a fixed width for both buttons */
  flex: 0 0 auto; /* Prevent flexbox from changing button size */
}

.review-application .accept-button {
  background-color: #4CAF50 !important;
}

.review-application .accept-button:hover {
  background-color: #45a049 !important;
}

.review-application .reject-button {
  background-color: #f44336 !important;
}

.review-application .reject-button:hover {
  background-color: #d32f2f !important;
}

.review-application .resume-group {
  margin-bottom: 20px;
}

.review-application .resume-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.review-application .resume-button {
  display: inline-block;
  padding: 8px 12px;
  background-color: #f0f0f0;
  color: #000000 !important; /* Changed to black and added !important */
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
}

.review-application .resume-button:hover {
  background-color: #e0e0e0;
  color: #000000 !important; /* Ensure text remains black on hover */
}

.review-application .file-name {
  font-size: 14px;
  color: #ddd;
}