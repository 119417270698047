.application-form {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #000000;
  border-radius: 8px;
  color: white;
  width: 100%; /* Add this line */
  box-sizing: border-box; /* Add this line */
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #ddd;
}

input[type="text"],
input[type="email"],
input[type="file"],
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #444;
  border-radius: 4px;
  background-color: #333;
  color: white;
}

textarea {
  height: 100px;
}

button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  margin: 20px auto 0;
  min-width: 200px;
}

button:hover {
  background-color: #45a049;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.success-message {
  color: #4CAF50;
}

.error-message {
  color: #f44336;
}

.company-logo {
  display: block;
  margin: 0 auto 30px;
  width: 80%; /* Change from fixed 400px to 80% */
  max-width: 400px; /* Add this line to limit maximum size */
  height: auto;
  object-fit: contain;
  border: none;
}

.position-description {
  margin-bottom: 30px;
  text-align: left;
  background-color: #1a1a1a;
  padding: 20px;
  border-radius: 8px;
}

.position-description p {
  color: #ffffff;
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 15px;
}

.position-description p:last-child {
  margin-bottom: 0;
}

.char-count {
  text-align: right;
  font-size: 0.8em;
  color: #888;
  margin-top: 5px;
}

.application-form button[type="submit"] {
  background-color: #4CAF50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  margin: 20px auto 0;
  min-width: 200px;
}

.application-form button[type="submit"]:hover {
  background-color: #45a049;
}

.application-form button[type="submit"]:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}