.success-page {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-color: #000000;
  border-radius: 8px;
  color: white;
  width: 100%; /* Add this line */
  box-sizing: border-box; /* Add this line */
}

.success-page h1 {
  color: white;
  margin-bottom: 1rem;
}

.success-page p {
  color: white;
  margin-bottom: 0.5rem;
}

.company-logo {
  display: block;
  margin: 0 auto 30px;
  width: 80%; /* Change from fixed 400px to 80% */
  max-width: 400px; /* Add this line to limit maximum size */
  height: auto;
  object-fit: contain;
  border: none;
}

.success-page-content {
  background-color: black;
  padding: 1rem;
  border-radius: 8px;
}